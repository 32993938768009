import { Box, Tabs, Tab, useTheme } from '@mui/material';
import React, { FC } from 'react';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
interface IFilterTabComponents {
  tab?: { title: string; onClick?: VoidFunction }[];
  setNewDataValue: React.Dispatch<React.SetStateAction<number>>;
}

const FilterTabComponents: FC<IFilterTabComponents> = ({
  tab,
  setNewDataValue,
}) => {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setNewDataValue(newValue);
  };

  return (
    <Box sx={{}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          indicatorColor='primary'
          variant='scrollable'
          scrollButtons
          allowScrollButtonsMobile
          value={value}
          onChange={handleChange}
          sx={{
            '& .MuiTabs-indicator': {
             
              height: '4px',
              backgroundColor: theme.colors?.warning.default,
            },
            border: 'none',
            Opacity: '0.9',
            width: '100%',
          }}
          color='error'
        >
          {tab?.map((item, index: number) => {
            return (
              <Tab
                key={index}
                style={{
                  color: value === index ? theme.colors?.warning.default : '',
                  textTransform: 'none',
                }}
                sx={{ mx: 1 }}
                label={<span>{item.title}</span>}
                {...a11yProps(0)}
                onClick={() => item.onClick && item.onClick()}
              />
            );
          })}
        </Tabs>
      </Box>
    </Box>
  );
};

export default FilterTabComponents;
