import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { CssBaseline } from '@mui/material';

import './App.css';

import AppStateProvider from './state/provider/AppStateProvider';
import ThemeSettings from './theme';
import router from './router';
import Loader from './components/common/Loader';

const App = () => {
  return (
    <div className='App'>
      <Suspense fallback={<Loader/>}>
        <AppStateProvider>
          <ThemeSettings>
            <CssBaseline />
            <RouterProvider router={router} />
          </ThemeSettings>
        </AppStateProvider>
      </Suspense>
     
    </div>
  );
};

export default App;
